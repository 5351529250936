import React from 'react'
import SEO from '../components/seo';
import Layout from '../components/layout';
import SecuritySections from '../components/security-page/security-sections'
import { graphql, StaticQuery } from 'gatsby';

export default function Security(){
    return (
        

        <StaticQuery
        query={graphql`
        query SecurityPageQuery {
          site {
            siteMetadata {
              title
              env
              imagesPrefix
            }
          }
        }
        `}
        render={data => (
          <Layout location="">
            <div className="security-wrapper">
              <SEO description='Security and privacy at Blix is a top priority and our team maintains the highest levels of security standards, encryptions, and authorizations to keep your accounts, emails, and chats secure.' />
              <SecuritySections imagesPrefix={data.site.siteMetadata.imagesPrefix} />
            </div>
          </Layout>
        )}
        />
    );
}

