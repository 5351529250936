import React from 'react'
import {Typography, Container, Avatar, Grid} from '@material-ui/core';
import {Link} from 'gatsby'

const source =[
    {
        title: 'Blix Security',
        subtitle: 'Security and privacy at Blix is a top priority and our team maintains the highest levels of security standards, encryptions, and authorizations to keep your accounts, emails, and chats secure.',
        image: "/image-1.svg",
        items: '',
        btn: '',
        secsubtitle: '',
        classes: 'security-first-section',
        icon: ''
    },
    {
        title: 'Security Highlights',
        subtitle: '',
        image: '',
        items: [
            {
                itemicon: "/token-security.svg",
                itemtitle: 'Secure OAuth Connections',
                itemsubtext: 'Blix uses the secure OAuth2 authorization protocol wherever possible for providers such as Gmail, Google Suite, Office365, and Yahoo, to access your account with a token only, without ever having to enter your password.',
                maxWidth: '30px'
            },
            {
                itemicon: "/protected-security.svg",
                itemtitle: 'Security Features within Blix',
                itemsubtext: 'Blix offers a secure Lock Screen feature , that utilizing passcode or fingerprint security, ensuring that your emails will remain safe even if you were to lose your device or hand it off to another person.',
                maxWidth: '30px'
            },
            {
                itemicon: "/backup-security.svg",
                itemtitle: 'Secure Account Backup and Desaster Recovery',
                itemsubtext: 'Blix’s Secure Account Backup and Recovery tool can really help you out when your phone gets lost or you simply want to upgrade to another device.',
                maxWidth: '35px'
            },
       
        ],
        btn: '',
        secsubtitle: '',
        classes: 'security-second-section',
        icon: ''
    },
    {
        title: 'Protection of Your Data (EU GDPR Compliant)',
        subtitle: 'We’re committed to partnering with our Customers and Users to help them understand and prepare for the General Data Protection Regulation (GDPR). Besides strengthening and standardizing user data privacy across the EU nations, it will require new or additional obligations on all organizations that handle EU citizens’ personal data, regardless of where the organizations themselves are located.',
        image: '',
        items: '',
        btn: '',
        secsubtitle: '',
        classes: 'security-third-section'
    },
    {
        title: 'Have a security related question or issue?',
        subtitle: <span style={{color: '#777777'}}>
            
            If you believe you have found a security vulnerability on Blix, we encourage <br/> you to  <a href="mailto:security@blix.net" style={{color: '#6C5CBD', fontWeight: 'bold', textDecoration: 'none'}}>get in touch</a> with our security team right away.</span>,
        image: '',
        items: '',
        btn: '',
        secsubtitle: <span style={{color: '#777777'}}><br></br>Read more about our <Link to="/privacy" style={{color: '#777777', fontWeight: 'bold', textDecoration: 'none'}}>Privacy Policy </Link> and <Link to="/tos" style={{color: '#777777', fontWeight: 'bold', textDecoration: 'none'}}>Terms of Service</Link>.</span>,
        classes: 'security-fourth-section',
        icon: "/lock-security.svg"
    },
]

const SecuritySections = ({imagesPrefix}) => {
    return (
        <div style={{paddingTop: '110px'}}>
        {source.map((data, i) => {
            return (
                data.classes !== 'security-third-section' ? 
                <div key={i} className={data.classes}>
                        {data.icon && <img src={imagesPrefix + data.icon} style={{maxWidth: '50px', marginBottom: '20px'}} alt="lock-security" />}
                         <Typography align="center" color="textPrimary" variant="h4" style={{fontWeight: 'bold'}}>{data.title}</Typography>
                        <Typography style={{maxWidth: '900px', margin: '10px auto', fontSize: '18px'}} align="center" color="textSecondary">{data.subtitle}</Typography>
                        {data.image && <img src={imagesPrefix + data.image} style={{width: '48%', minWidth: '280px'}} alt="why-you-need-blix"/>}
                        {data.items ? 
                            <Grid container direction="row" justify="center" alignItems="center" style={{marginTop: '50px'}} spacing={3}>
                                {data.items ? data.items.map((item, i) => {
                                    return (
                                        <Grid key={i} item md={3} sm={12} xs={12}>
                                            <Avatar className="avatar">
                                                <img src={imagesPrefix + item.itemicon} style={{maxWidth: item.maxWidth}} alt={item.itemtitle}/>
                                            </Avatar>
                                            <Typography color="textPrimary" align="center" style={{margin: '10px auto', maxWidth: '250px', fontSize: '22px', fontWeight: 'bold'}}>{item.itemtitle}</Typography>
                                            <Typography color="textSecondary" align="center" style={{margin: '10px auto', maxWidth: '400px'}} className="items-subtext">{item.itemsubtext}</Typography>
                                        </Grid>
                                    );
                                }) : ''
                                }
                            </Grid>
                            : ''}
                        
                        <Typography style={{maxWidth: '900px', margin: '10px auto', fontSize: '18px'}} align="center" color="textSecondary">{data.secsubtitle}</Typography>
                </div>
                      : 
                      <Container key={i} fixed className={data.classes}>
                      <Grid container direction="row" justify="center" alignItems="center" spacing={10}>
                          <Grid item md={6} xs={12}>
                                <img style={{width: '82%'}} src={`${imagesPrefix}/image-2.svg`} alt="img-2"/>
                          </Grid>
                          <Grid item md={6} xs={12}>
                              <Typography color="textPrimary" variant="h5" style={{fontWeight: 'bold'}}>{data.title}</Typography>
                              <Typography color="textSecondary" style={{fontSize: '17px', marginTop: '7px'}}>{data.subtitle}</Typography>
                          </Grid>
                      </Grid>      
                      </Container>
            );
        })}
        </div>
    );
}

export default SecuritySections;